import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  // scroll to the top on route changes as long as the route doesn't contain a hash 
  // (hashes are used in some of the footer links on the index page)
  const { hash } = location
  if (hash === '') {
    window.scrollTo(0, 0)
  }
  return false;
}

export const wrapRootElement = ({ element }) => {
  return <QueryClientProvider client={queryClient}>{element}</QueryClientProvider>
}